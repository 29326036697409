import React from 'react'

export default function Services() {
    return (
        <section id='services'>
            <div className="container">
                <div className="title">
                    <h2>Services</h2>
                </div>
                <div className="cards">
                    <div className="card">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="154" height="154" viewBox="0 0 154 154" fill="none">
                                <path d="M34.995 34.995H15V15H34.995V34.995ZM18.9912 31.0037H31.0037V18.9912H18.9912V31.0037Z" fill="#42FF00" />
                                <path d="M139 34.995H119.005V15H139V34.995ZM122.996 31.0037H134.989V18.9912H122.996V31.0037Z" fill="#42FF00" />
                                <path d="M86.9975 34.995H67.0025V15H86.9975V34.995ZM70.9937 31.0037H83.0062V18.9912H70.9937V31.0037Z" fill="#42FF00" />
                                <path d="M34.995 139H15V119.005H34.995V139ZM18.9912 135.009H31.0037V122.996H18.9912V135.009Z" fill="#42FF00" />
                                <path d="M34.995 86.9975H15V67.0025H34.995V86.9975ZM18.9912 83.0062H31.0037V70.9937H18.9912V83.0062Z" fill="#42FF00" />
                                <path d="M139 86.9975H119.005V67.0025H139V86.9975ZM122.996 83.0062H134.989V70.9937H122.996V83.0062Z" fill="#42FF00" />
                                <path d="M139 139H119.005V119.005H139V139ZM122.996 135.009H134.989V122.996H122.996V135.009Z" fill="#42FF00" />
                                <path d="M86.9975 139H67.0025V119.005H86.9975V139ZM70.9937 135.009H83.0062V122.996H70.9937V135.009Z" fill="#42FF00" />
                                <path d="M68.9981 23.0019H32.9994V26.9931H68.9981V23.0019Z" fill="#42FF00" />
                                <path d="M121.001 23.0019H85.0019V26.9931H121.001V23.0019Z" fill="#42FF00" />
                                <path d="M68.9981 127.007H32.9994V130.998H68.9981V127.007Z" fill="#42FF00" />
                                <path d="M121.001 127.007H85.0019V130.998H121.001V127.007Z" fill="#42FF00" />
                                <path d="M130.998 32.9994H127.007V68.9981H130.998V32.9994Z" fill="#42FF00" />
                                <path d="M130.998 85.0019H127.007V121.001H130.998V85.0019Z" fill="#42FF00" />
                                <path d="M26.9931 32.9994H23.0019V68.9981H26.9931V32.9994Z" fill="#42FF00" />
                                <path d="M26.9931 85.0019H23.0019V121.001H26.9931V85.0019Z" fill="#42FF00" />
                                <path d="M77 118.753L53.75 53.75L118.753 77L88.625 88.625L77 118.753ZM60.2987 60.2987L77 107.244L85.3894 85.4475L107.244 77L60.2987 60.2987Z" fill="#42FF00" />
                                <path d="M60.9963 39.0056H57.005V47.0075H60.9963V39.0056Z" fill="#42FF00" />
                                <path d="M46.2702 43.4523L43.448 46.2745L49.1062 51.9327L51.9284 49.1104L46.2702 43.4523Z" fill="#42FF00" />
                                <path d="M47.0075 57.005H39.0056V60.9963H47.0075V57.005Z" fill="#42FF00" />
                                <path d="M49.1042 66.0666L43.446 71.7248L46.2683 74.5471L51.9265 68.8889L49.1042 66.0666Z" fill="#42FF00" />
                                <path d="M60.9963 70.9937H57.005V78.9956H60.9963V70.9937Z" fill="#42FF00" />
                                <path d="M78.9956 57.005H70.9937V60.9963H78.9956V57.005Z" fill="#42FF00" />
                                <path d="M71.7205 43.4542L66.0623 49.1124L68.8846 51.9346L74.5428 46.2764L71.7205 43.4542Z" fill="#42FF00" />
                            </svg>
                        </div>
                        <div className="card-title">
                            <h3>Frontend Dasturchi</h3>
                            <p>Men Frontend Developerman va bu borada ancha tajribaga egaman.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="154" height="154" viewBox="0 0 154 154" fill="none">
                                <path d="M129.003 113.013H24.9975C22.3476 113.008 19.8076 111.953 17.9339 110.079C16.0601 108.205 15.0051 105.665 15 103.014V34.9989C15.0051 32.3486 16.0601 29.8083 17.9339 27.9343C19.8076 26.0602 22.3476 25.0051 24.9975 25H129.003C131.652 25.0051 134.192 26.0602 136.066 27.9343C137.94 29.8083 138.995 32.3486 139 34.9989V103.014C138.995 105.665 137.94 108.205 136.066 110.079C134.192 111.953 131.652 113.008 129.003 113.013ZM24.9975 28.9918C23.4045 28.9918 21.8768 29.6247 20.7504 30.7512C19.624 31.8778 18.9912 33.4057 18.9912 34.9989V103.014C18.9912 103.802 19.1467 104.583 19.4488 105.31C19.7509 106.038 20.1936 106.699 20.7516 107.255C21.3096 107.811 21.9719 108.252 22.7005 108.552C23.4291 108.852 24.2097 109.005 24.9975 109.002H129.003C129.79 109.005 130.571 108.852 131.3 108.552C132.028 108.252 132.69 107.811 133.248 107.255C133.806 106.699 134.249 106.038 134.551 105.31C134.853 104.583 135.009 103.802 135.009 103.014V34.9989C135.009 33.4057 134.376 31.8778 133.25 30.7512C132.123 29.6247 130.595 28.9918 129.003 28.9918H24.9975Z" fill="#42FF00" />
                                <path d="M52.9944 111.017H49.0031V127.023H52.9944V111.017Z" fill="#42FF00" />
                                <path d="M104.997 111.017H101.006V127.023H104.997V111.017Z" fill="#42FF00" />
                                <path d="M108.988 125.008H44.9925V129H108.988V125.008Z" fill="#42FF00" />
                                <path d="M137.004 97.0071H16.9957V100.999H137.004V97.0071Z" fill="#42FF00" />
                                <path d="M76.9999 85.0707C72.6484 85.0746 68.3934 83.7882 64.7726 81.3743C61.1518 78.9604 58.3277 75.5272 56.6572 71.5086C54.9866 67.4899 54.5446 63.0662 55.387 58.7965C56.2294 54.5267 58.3183 50.6024 61.3899 47.5196C64.4614 44.4368 68.3778 42.3338 72.644 41.4763C76.9102 40.6188 81.3348 41.0453 85.3587 42.7019C89.3827 44.3585 92.8253 47.1709 95.2516 50.7837C97.678 54.3965 98.9791 58.6475 98.9906 62.9996C98.9957 65.8929 98.4309 68.7589 97.3285 71.434C96.2261 74.109 94.6077 76.5407 92.5657 78.5902C90.5237 80.6397 88.0981 82.2669 85.4273 83.3788C82.7565 84.4907 79.8929 85.0657 76.9999 85.0707ZM76.9999 45.0559C73.44 45.0559 69.96 46.1117 67 48.0898C64.04 50.0678 61.733 52.8793 60.3707 56.1687C59.0084 59.4581 58.6519 63.0777 59.3464 66.5697C60.0409 70.0617 61.7552 73.2694 64.2725 75.787C66.7897 78.3046 69.9969 80.0191 73.4884 80.7137C76.98 81.4083 80.5991 81.0518 83.888 79.6893C87.177 78.3268 89.9881 76.0194 91.9659 73.059C93.9437 70.0986 94.9993 66.6182 94.9993 63.0577C95.0044 60.6895 94.5427 58.3435 93.6407 56.1539C92.7387 53.9643 91.414 51.9739 89.7423 50.2966C88.0707 48.6193 86.0849 47.288 83.8984 46.3788C81.712 45.4696 79.3678 45.0003 76.9999 44.9978V45.0559Z" fill="#42FF00" />
                                <path d="M50.9987 85.0708C46.6481 85.0707 42.395 83.7812 38.7767 81.3649C35.1585 78.9486 32.3374 75.5141 30.6698 71.4951C29.0022 67.4762 28.5629 63.0531 29.4075 58.7846C30.2521 54.5161 32.3426 50.5936 35.4149 47.5128C38.4872 44.4319 42.4036 42.3308 46.6693 41.4748C50.935 40.6189 55.3586 41.0466 59.3814 42.7038C63.4042 44.361 66.8457 47.1734 69.2712 50.7857C71.6966 54.3981 72.9973 58.6484 73.0087 62.9996C73.0138 65.8946 72.4484 68.7622 71.3448 71.4385C70.2413 74.1148 68.6211 76.5473 66.5771 78.5971C64.533 80.6468 62.105 82.2736 59.432 83.3844C56.759 84.4952 53.8933 85.0682 50.9987 85.0708ZM50.9987 45.0559C47.4388 45.0559 43.9588 46.1117 40.9988 48.0898C38.0388 50.0678 35.7318 52.8793 34.3695 56.1688C33.0072 59.4582 32.6507 63.0777 33.3452 66.5697C34.0397 70.0617 35.754 73.2694 38.2713 75.787C40.7885 78.3046 43.9957 80.0191 47.4872 80.7137C50.9788 81.4083 54.5979 81.0518 57.8868 79.6893C61.1758 78.3268 63.9869 76.0194 65.9647 73.059C67.9425 70.0986 68.9981 66.6182 68.9981 63.0578C69.0032 60.6895 68.5415 58.3436 67.6395 56.1539C66.7375 53.9643 65.4128 51.9739 63.7411 50.2966C62.0695 48.6194 60.0837 47.288 57.8972 46.3788C55.7108 45.4696 53.3666 45.0003 50.9987 44.9978V45.0559Z" fill="#42FF00" />
                                <path d="M103.001 85.0707C98.6498 85.0746 94.3948 83.7882 90.774 81.3743C87.1532 78.9604 84.3291 75.5272 82.6585 71.5086C80.988 67.4899 80.5459 63.0662 81.3883 58.7965C82.2307 54.5267 84.3196 50.6024 87.3912 47.5196C90.4628 44.4368 94.3791 42.3338 98.6453 41.4763C102.912 40.6188 107.336 41.0453 111.36 42.7019C115.384 44.3585 118.827 47.1709 121.253 50.7837C123.679 54.3965 124.98 58.6475 124.992 62.9996C124.997 68.8414 122.684 74.4465 118.561 78.5846C114.438 82.7226 108.842 85.0553 103.001 85.0707ZM103.001 45.0559C99.4413 45.0559 95.9613 46.1117 93.0014 48.0898C90.0414 50.0678 87.7344 52.8793 86.372 56.1687C85.0097 59.4581 84.6533 63.0777 85.3478 66.5697C86.0423 70.0617 87.7565 73.2694 90.2738 75.787C92.7911 78.3046 95.9982 80.0191 99.4898 80.7137C102.981 81.4083 106.6 81.0518 109.889 79.6893C113.178 78.3268 115.989 76.0194 117.967 73.059C119.945 70.0986 121.001 66.6182 121.001 63.0577C121.006 60.6895 120.544 58.3435 119.642 56.1539C118.74 53.9643 117.415 51.9739 115.744 50.2966C114.072 48.6193 112.086 47.288 109.9 46.3788C107.713 45.4696 105.369 45.0003 103.001 44.9978V45.0559Z" fill="#42FF00" />
                            </svg>
                        </div>
                        <div className="card-title">
                            <h3>Grafik Dizayner</h3>
                            <p>Men dizaynni yaxshi ko'raman va bu har qanday veb-sayt yaratishdan oldin birinchi qadam, chunki men o'z tasavvurlarimga maket bera olaman.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="154" height="154" viewBox="0 0 154 154" fill="none">
                                <path d="M139 127H15V27H139V127ZM18.9912 123.009H135.009V30.9915H18.9912V123.009Z" fill="#42FF00" />
                                <path d="M93.0037 79.0054H23.0019V35.0023H93.0037V79.0054ZM26.9931 74.9946H88.9931V38.9938H26.9931V74.9946Z" fill="#42FF00" />
                                <path d="M130.998 35.0023H96.995V38.9938H130.998V35.0023Z" fill="#42FF00" />
                                <path d="M130.998 43.0047H96.995V46.9961H130.998V43.0047Z" fill="#42FF00" />
                                <path d="M130.998 51.007H96.995V54.9984H130.998V51.007Z" fill="#42FF00" />
                                <path d="M130.998 59.0093H96.995V63.0008H130.998V59.0093Z" fill="#42FF00" />
                                <path d="M130.998 67.0116H96.995V71.0031H130.998V67.0116Z" fill="#42FF00" />
                                <path d="M130.998 74.9946H96.995V78.986H130.998V74.9946Z" fill="#42FF00" />
                                <path d="M57.005 118.998H23.0019V82.9969H57.005V118.998ZM26.9931 115.006H52.9944V87.0659H26.9931V115.006Z" fill="#42FF00" />
                                <path d="M93.0037 118.998H60.9963V82.9969H93.0037V118.998ZM65.0069 115.006H88.9931V87.0659H65.0069V115.006Z" fill="#42FF00" />
                                <path d="M130.998 118.998H96.995V82.9969H130.998V118.998ZM101.006 115.006H127.007V87.0659H101.006V115.006Z" fill="#42FF00" />
                                <path d="M57.005 71.0031C54.2345 71.0031 51.5261 70.1815 49.2225 68.6422C46.9189 67.1029 45.1234 64.915 44.0632 62.3552C43.0029 59.7954 42.7255 56.9786 43.266 54.2612C43.8065 51.5437 45.1407 49.0476 47.0998 47.0884C49.0588 45.1292 51.5548 43.795 54.2722 43.2545C56.9895 42.7139 59.806 42.9913 62.3657 44.0516C64.9253 45.1119 67.1131 46.9075 68.6523 49.2113C70.1916 51.515 71.0131 54.2235 71.0131 56.9942C71.0131 60.7096 69.5373 64.2728 66.9102 66.9C64.2832 69.5272 60.7202 71.0031 57.005 71.0031ZM57.005 46.9961C55.0277 46.9961 53.0948 47.5825 51.4507 48.6811C49.8066 49.7797 48.5252 51.3412 47.7685 53.1681C47.0118 54.995 46.8138 57.0053 47.1996 58.9447C47.5854 60.8841 48.5375 62.6656 49.9357 64.0639C51.3339 65.4621 53.1153 66.4144 55.0546 66.8001C56.9939 67.1859 59.0041 66.9879 60.8309 66.2312C62.6577 65.4745 64.2191 64.193 65.3176 62.5488C66.4162 60.9046 67.0025 58.9716 67.0025 56.9942C66.9974 54.3441 65.9424 51.804 64.0686 49.9301C62.1949 48.0563 59.6549 47.0012 57.005 46.9961Z" fill="#42FF00" />
                            </svg>
                        </div>
                        <div className="card-title">
                            <h3>Buxgalter</h3>
                            <p>Men hisob kitob ishlarini juda yaxshi ko'raman va bu miyani ancha charxlaydi.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
