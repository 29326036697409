import React from 'react'
import line from "../image/Background Elements/Ornament.png"

export default function Line() {
    return (
        <section id="line">
            <div className="container">
                <img src={line} alt="line" />
            </div>
        </section>
    )
}
